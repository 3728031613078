<template>
  <div class="VoucherList">
    <van-nav-bar title="我的油卡" fixed safe-area-inset-top />
    <div class="container">
      <van-tabs v-model="tab" @change="handleChangeTab">
        <van-tab title="未使用" name="0" />
        <van-tab title="已使用" name="1" />
      </van-tabs>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell
          v-for="item in list"
          :key="item.key"
          :title="item.title"
          :value="item.status"
          @click="handleClickVoucher(item)"
        />
      </van-list>
    </div>
    <van-dialog v-model="show" show-cancel-button>
      <img
        src="../../assets/93489234018401.png"
        class="qrcode"
      >
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      list: [],
      tab: "0",
      loading: false,
      finished: false,
      show: false
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          const _random = Math.floor(Math.random(1, 10) * 100)
          if (this.tab === "0") {
            this.list.push({
              key: [this.tab, _random, Date.now()].join('_'),
              title: _random + "元 汽油券",
              status: "查看券码",
            });
          } else {
            this.list.push({
              key: [this.tab, _random, Date.now()].join('_'),
              title: _random + "元 汽油券",
              status: "已核销",
            });
          }
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    handleClickVoucher() {
      Dialog.confirm({
        title: "提示",
        message:
          " 此券不设找零，只可在指定加油站使用；查看券码后无法退款，请注意",
      }).then(() => {
        Dialog.confirm({
          title: "再次提示",
          message: "券码展示后不可退款，请您知晓",
        }).then(() => {
             this.show = true
        });
      });
    },
    handleChangeTab() {
      this.list = [];
      this.onLoad();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding-top: 46px;
  .van-cell__title {
    text-align: left;
  }

}

  .qrcode {
    display: block;
    width: 100%;
  }
</style>